// src/components/Layout/index.jsx

import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Header from '../Header';

export default function Layout() {
  const { lang } = useParams(); // Obtener el idioma de la URL

  return (
    <>
      <Header logoSrc="/images/Imagotipo_Blanco.svg" variant="cs_heading_color" />
      <Outlet />
    </>
  );
}
