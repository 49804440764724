// src/components/LanguageSwitcher/LanguageSwitcher.jsx

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChevronDown } from 'lucide-react';

const LanguageSwitcher = ({ languages, translations, currentLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((lang) => lang.code === currentLanguage) || languages[0]
  );

  useEffect(() => {
    const foundLang = languages.find((lang) => lang.code === currentLanguage);
    if (foundLang) {
      setSelectedLanguage(foundLang);
    } else {
      setSelectedLanguage(languages.find((lang) => lang.code === 'es'));
    }
  }, [currentLanguage, languages]);

  const constructHref = (language) => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const isHomePage =
      pathSegments.length === 0 ||
      (pathSegments.length === 1 && languages.some((lang) => lang.code === pathSegments[0]));

    if (isHomePage) {
      // Si estamos en la página de inicio
      if (language.code === 'es') {
        return '/';
      } else {
        return `/${language.code}/`;
      }
    } else {
      // Si no estamos en la página de inicio, utilizamos translations
      if (language.code === 'es') {
        return translations['es'] ? `/${translations['es']}` : '/';
      } else {
        return translations[language.code]
          ? `/${language.code}/${translations[language.code]}`
          : `/${language.code}/`;
      }
    }
  };

  return (
    <div className="dropdown language-switcher d-inline-block">
      <button
        className="dropdown-toggle btn btn-link text-decoration-none text-color-grey"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <img
          src={selectedLanguage.flag}
          alt={`${selectedLanguage.name} flag`}
          className="me-1"
          style={{ width: '20px', height: '15px' }}
        />
        <span className="language-switch" style={{ color: '#4a4a49' }}>
          {selectedLanguage.name}
        </span>
        <ChevronDown className="ms-1 text-white" size={16} />
      </button>

      <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
        {languages.map((language) => (
          <Link
            key={language.code}
            to={constructHref(language)}
            className="dropdown-item d-flex align-items-center"
            onClick={() => setIsOpen(false)}
          >
            <img
              src={language.flag}
              alt={`${language.name} flag`}
              className="shadow-sm me-2"
              style={{ width: '20px', height: '15px' }}
            />
            <span>{language.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

LanguageSwitcher.propTypes = {
  languages: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default LanguageSwitcher;
