// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import './i18n';
import { TranslationProvider } from './contexts/TranslationContext';

const AppWrapper = () => {
  const { i18n } = useTranslation();

  // Detecta si la URL contiene un prefijo de idioma
  const isLanguageInURL = /^\/(en|pt|fr|it|de|au)/.test(window.location.pathname);
  const currentLanguage = isLanguageInURL ? i18n.language : 'es';

  return (
    <HelmetProvider>
      <Helmet>
        <html lang={currentLanguage} />
      </Helmet>
      <App />
    </HelmetProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <TranslationProvider>
        <AppWrapper />
      </TranslationProvider>
    </BrowserRouter>
  </React.StrictMode>
);
