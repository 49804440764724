// src/helpers/GetBlogArticle.js
import axios from 'axios';

const BASE_URL = 'https://dashboard.gestlifesurrogacy.com/api/articles';

export const getBlogArticles = async (page = 1) => {
  try {
    const response = await axios.get(`${BASE_URL}?page=${page}`);
    return response.data; // Retorna la respuesta completa
  } catch (error) {
    console.error('Error fetching blog articles', error);
    throw error;
  }
};

export const getArticleBySlug = async (slug) => {
  try {
    const response = await axios.get(`${BASE_URL}/${slug}`);
    return response.data; // Retorna el artículo específico
  } catch (error) {
    console.error(`Error fetching article with slug ${slug}`, error);
    throw error;
  }
};
