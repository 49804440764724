// src/components/Pagination/index.jsx
import React from 'react';
import PropTypes from 'prop-types';

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  // Generar los números de página
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="cs_pagination_box">
      {/* Botón para ir a la página anterior */}
      <li>
        <button
          className="cs_pagination_arrow cs_center"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <img src="/images/icons/left_arrow_blue.svg" alt="Icon" />
        </button>
      </li>

      {/* Renderizar los botones de paginación dinámicamente */}
      {pageNumbers.map((page) => (
        <li key={page}>
          <button
            className={`cs_pagination_item cs_center ${currentPage === page ? 'active' : ''}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        </li>
      ))}

      {/* Botón para ir a la página siguiente */}
      <li>
        <button
          className="cs_pagination_arrow cs_center"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <img src="/images/icons/right_arrow_blue.svg" alt="Icon" />
        </button>
      </li>
    </ul>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
