// src/components/Pages/Blog.jsx

import React, { useState, useEffect } from 'react';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
import AppointmentSection from '../Section/AppointmentSection';
import { getBlogArticles } from '../../helpers/GetBlogArticle';
import Pagination from '../Pagination';
import { useParams } from 'react-router-dom';

export default function Blog() {
  const { lang } = useParams();
  pageTitle('Blog');

  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getBlogArticles(currentPage, lang);
        setArticles(data.data);
        setTotalPages(data.last_page);
        setIsLoading(false);

        if (window.snapSaveState) {
          window.snapSaveState();
        }
      } catch (error) {
        console.error('Error fetching blog articles', error);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [currentPage, lang]);

  const blogData = articles.map((article) => ({
    title: article.title,
    thumbUrl: `https://dashboard.gestlifesurrogacy.com/${article.image}`,
    date: new Date(article.date).toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    btnText: 'Leer más',
    href: `${lang ? `/${lang}` : ''}/blog/${article.slug}/`,
    socialShare: true,
  }));

  return (
    <>
      <Section
        style={{
          background: 'linear-gradient(40deg, #EE6FBB, #7BADD3)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '50px 0',
        }}
      />
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="GestLife Blog" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        {isLoading ? (
          <p>Cargando artículos...</p>
        ) : (
          <BlogSectionStyle2 data={blogData} />
        )}
      </Section>
      <Section bottomMd={50} bottomLg={50} bottomXl={50}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Información"
          sectionTitleUp="Solicita"
          imgUrl="/images/contacto_lo.jpg"
        />
      </Section>
    </>
  );
}
