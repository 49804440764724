// src/components/Header/Header.jsx

import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import axios from 'axios';
import DropDown from './DropDown';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import IconBoxStyle11 from '../IconBox/IconBoxStyle11';
import Spacing from '../Spacing';
import { TranslationContext } from '../../contexts/TranslationContext';

const languages = [
  {
    code: 'en',
    name: 'Eng',
    flag: '/images/languages/united-kingdom-uk-svgrepo-com.svg',
  },
  {
    code: 'de',
    name: 'Ger',
    flag: '/images/languages/germany-svgrepo-com.svg',
  },
  {
    code: 'au',
    name: 'Aus',
    flag: '/images/languages/australia-svgrepo-com.svg',
  },
  {
    code: 'es',
    name: 'Spa',
    flag: '/images/languages/spain-svgrepo-com.svg',
  },
  {
    code: 'fr',
    name: 'Fra',
    flag: '/images/languages/france-svgrepo-com.svg',
  },
  {
    code: 'it',
    name: 'Ita',
    flag: '/images/languages/italy-svgrepo-com.svg',
  },
];

export default function Header({ logoSrc, variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [sideNav, setSideNav] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const { pathname } = useLocation();
  const { translations } = useContext(TranslationContext);

  const logoDegradadoSrc = '/images/Logotipo_Color.svg';

  const getCurrentLanguage = () => {
    const pathSegments = pathname.split('/').filter(Boolean);
    const lang = pathSegments[0];
    const supportedLanguages = languages.map((lang) => lang.code);
    return supportedLanguages.includes(lang) ? lang : 'es'; // 'es' como idioma por defecto
  };

  const currentLanguage = getCurrentLanguage();

  const isHomePage =
    pathname === '/' ||
    pathname === `/${currentLanguage}/` ||
    pathname === `/${currentLanguage}`;

  const ensureTrailingSlash = (path) => {
    if (!path) return '/';
    if (path.includes('#') || path.includes('/#')) {
      return path.replace(/\/+$/, '');
    }
    return path.endsWith('/') ? path : `${path}/`;
  };

  const sanitizeSlug = (slug) => {
    return slug ? slug.replace(/\/+$/, '') : '';
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get('https://dashboard.gestlifesurrogacy.com/public/api/menu')
      .then((response) => {
        const menuData = response.data;
        const filteredMenu = menuData
          .map((item) => {
            if (item.locale === currentLanguage) return item;
            const translation = item.translations.find(
              (t) => t.locale === currentLanguage
            );
            if (translation) {
              return {
                ...item,
                ...translation,
                slug: translation.slug || item.slug,
                name: translation.name || item.name,
              };
            }
            return null;
          })
          .filter(Boolean);

        setMenuItems(filteredMenu);
      })
      .catch((error) => {
        console.error('Error fetching menu items:', error);
      });
  }, [currentLanguage]);

  const buildMenuTree = (items, parentId = null) => {
    const filteredItems = items.filter((item) => item.parent_id === parentId);

    const mappedItems = filteredItems.map((item) => ({
      ...item,
      slug: sanitizeSlug(item.slug),
      children: buildMenuTree(items, item.id),
    }));

    return mappedItems;
  };

  const renderMenuItems = (items) => {
    return items.map((item) => {
      if (!item.slug && !item.link) {
        console.warn(
          `El ítem del menú con id ${item.id} no tiene 'slug' ni 'link'. Se omitirá.`
        );
        return null;
      }

      const sanitizedSlug = sanitizeSlug(item.slug || item.link);

      let basePath = '';
      if (currentLanguage !== 'es') {
        basePath = `/${currentLanguage}/${sanitizedSlug}`;
      } else {
        basePath = `/${sanitizedSlug}`;
      }

      const itemLink = ensureTrailingSlash(basePath);
      const isAnchorLink = itemLink.includes('#');

      return (
        <li
          key={item.id}
          className={item.children.length > 0 ? 'menu-item-has-children' : ''}
        >
          {isAnchorLink ? (
            <a href={itemLink} onClick={() => setMobileToggle(false)}>
              {item.name}
            </a>
          ) : (
            <Link to={itemLink} onClick={() => setMobileToggle(false)}>
              {item.name}
            </Link>
          )}
          {item.children.length > 0 && (
            <DropDown>
              <ul>{renderMenuItems(item.children)}</ul>
            </DropDown>
          )}
        </li>
      );
    });
  };

  const menuTree = buildMenuTree(menuItems);

  return (
    <>
      <header
        className={`cs_site_header cs_style1 cs_sticky_header ${
          mobileToggle ? 'cs_mobile_toggle_active' : ''
        } ${variant} ${isSticky ? 'cs_active_sticky' : ''}`}
      >
        <div className="cs_main_header">
          <div className="container">
            <div className="cs_main_header_in">
              <div className="cs_main_header_left">
                <Link
                  className="cs_site_branding"
                  to={currentLanguage !== 'es' ? `/${currentLanguage}/` : '/'}
                >
                  <img
                    src={isSticky ? logoDegradadoSrc : logoSrc}
                    alt="Logo"
                    className="site-logo"
                  />
                </Link>
                <nav className="cs_nav">
                  <ul
                    className={`${
                      mobileToggle ? 'cs_nav_list cs_active' : 'cs_nav_list'
                    }`}
                  >
                    {renderMenuItems(menuTree)}
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs_menu_toggle cs_toggle_active'
                        : 'cs_menu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </nav>
              </div>

              <div className="cs_main_header_right">
                {/* Integración del LanguageSwitcher */}
                <LanguageSwitcher
                  languages={languages}
                  translations={translations}
                  currentLanguage={currentLanguage}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Resto del código permanece sin cambios */}
    </>
  );
}
