import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const FormStyle1 = () => {
  const { t } = useTranslation();  // Hook para acceder a las traducciones

  // Definir los valores de los campos ocultos
  const hiddenFields = {
    option: 'INSERT_DATA',
    form_type: 'floating',
    form_name_id: 'tu_form_name_id',       // Reemplaza con el valor real
    campaign_origin_id: '',
    origin_id: '8',
    lead_type_id: '3',
    market_id: 'tu_marketType',            // Reemplaza con el valor real
  };

  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    telefono: '',
    politica: false,
    ...hiddenFields,  // Incluir los campos ocultos en el estado
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      telefono: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construir los datos para enviar en formato correcto
    const data = new URLSearchParams();

    // Añadir todos los campos del formulario, incluidos los ocultos
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    // Enviar los datos al backend PHP
    try {
      const response = await fetch('https://cdn.gestlife.com/assets/functions/leadcontrol/form-execute.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data.toString(),
      });

      if (response.ok) {
        // Manejar la respuesta exitosa
        console.log('Formulario enviado con éxito');
        // Opcional: Resetear el formulario o mostrar un mensaje al usuario
        setFormData({
          nombre: '',
          email: '',
          telefono: '',
          politica: false,
          ...hiddenFields,  // Reiniciar los campos ocultos
        });
      } else {
        // Manejar el error en la respuesta
        console.error('Error al enviar el formulario');
        // Opcional: Guardar los datos en el servidor o notificar al usuario
      }
    } catch (error) {
      console.error('Error de red:', error);
      // Opcional: Implementar una lógica de respaldo aquí
    }
  };

  return (
    <div className="form-style-1-container">
      <form onSubmit={handleSubmit} className="form-style-1">
        {/* Campos ocultos */}
        {Object.keys(hiddenFields).map((key) => (
          <input
            key={key}
            type="hidden"
            name={key}
            value={hiddenFields[key]}
          />
        ))}

        {/* Form Header */}
        <div className="form-header">
          <h3>{t('quieres')}</h3>
          <p>{t('ayudamos')}</p>
        </div>

        {/* Form Body */}
        <div className="form-body">
          {/* Nombre Apellidos */}
          <div className="form-group">
            <input
              type="text"
              name="nombre"
              required
              value={formData.nombre}
              onChange={handleChange}
              className="form-control"
              placeholder={t('nombre')}
            />
          </div>

          {/* Correo Electrónico */}
          <div className="form-group">
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder={t('email')}
            />
          </div>

          {/* Teléfono */}
          <div className="form-group phone-input">
            <PhoneInput
              country={'es'}  // País predeterminado
              value={formData.telefono}
              onChange={handlePhoneChange}
              inputClass="form-control telefono-input"
              enableSearch={true}  // Activa la búsqueda de país
              searchPlaceholder={t('telefono')}  // Traducción del placeholder
            />
          </div>

          {/* Aceptación de Política de Privacidad */}
          <div className="form-group checkbox-group">
            <input
              type="checkbox"
              name="politica"
              required
              checked={formData.politica}
              onChange={handleChange}
            />
            <label htmlFor="politica">
              {t('politica')}
            </label>
          </div>

          {/* Botón de Envío */}
          <div className="form-group submit-group">
            <button type="submit" className="submit-button">
              {t('solicitar')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormStyle1;
