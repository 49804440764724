// src/components/Pages/Services.jsx
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { getPageBySlug } from '../../helpers/Api';
import Header from '../Header';
import Footer from '../Footer';
import Section from '../Section';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import AboutSectionStyle2 from '../Section/AboutSection/AboutSectionStyle2';
import DepartmentSectionStyle4 from '../Section/DepartmentSection/DepartmentSectionStyle4';
import FormStyle1 from '../Form/FormStyle1';
import Accordion from '../Accordion';
import ReactDOMServer from 'react-dom/server';
import parse from 'html-react-parser';
import languages from '../../config/languages';
import { TranslationContext } from '../../contexts/TranslationContext';

// Función para obtener la base URL
const getBaseUrl = () => 'https://dashboard.gestlifesurrogacy.com';

// Función para obtener una imagen de respaldo si la original no está disponible
const getFallbackImage = (type) => {
  switch (type) {
    case 'bg':
      return '/images/fallback-bg.webp';
    case 'img':
      return '/images/fallback-img.webp';
    default:
      return '/images/default.webp';
  }
};

// Procesar contenido HTML para actualizar rutas de imágenes y agregar lazy loading
const processHtmlContent = (html, baseUrl) => {
  if (typeof document === 'undefined') return html;

  const div = document.createElement('div');
  div.innerHTML = html;
  const images = div.getElementsByTagName('img');

  for (let img of images) {
    const src = img.getAttribute('src');
    if (src.startsWith('/uploads')) {
      img.setAttribute('src', baseUrl + src);
    }
    img.setAttribute('loading', 'lazy'); // Agrega lazy loading a cada imagen
  }

  return div.innerHTML;
};

// Funciones para reemplazar shortcodes con componentes React
const replaceAboutSection = (html) => {
  const aboutSectionRegex = /--about-section title="([^"]+)" subTitle="([^"]+)" imgUrl="([^"]+)"--/g;
  return html.replace(aboutSectionRegex, (match, title, subTitle, imgUrl) => {
    const aboutSectionComponent = (
      <AboutSectionStyle2
        title={title}
        subTitle={subTitle}
        imgUrl={imgUrl}
      />
    );
    return ReactDOMServer.renderToString(aboutSectionComponent);
  });
};

const replaceDepartmentSection = (html) => {
  const departmentSectionRegex = /--department-section sectionTitle="([^"]+)" sectionTitleUp="([^"]+)" data='([^']+)'--/g;
  return html.replace(departmentSectionRegex, (match, sectionTitle, sectionTitleUp, data) => {
    const parsedData = JSON.parse(data);
    return `<div data-component="DepartmentSectionStyle4" data-props='${JSON.stringify({
      sectionTitle,
      sectionTitleUp,
      data: parsedData,
    })}'></div>`;
  });
};

const replaceFormStyle1 = (html) => {
  const formStyle1Regex = /--form-style-1--/g;
  return html.replace(formStyle1Regex, () => `<div data-component="FormStyle1"></div>`);
};

const replaceAccordion = (html) => {
  const accordionRegex = /--accordion\[(\[[\s\S]*?\])\]accordion--/g;
  return html.replace(accordionRegex, (match, jsonData) => {
    try {
      const parsedData = JSON.parse(jsonData.trim()).map((item) => ({
        title: item.question,
        content: item.answer,
      }));

      const props = JSON.stringify({
        data: parsedData,
        variant: 'cs_accordians text-color-grey cs_style1',
      }).replace(/'/g, '&#39;');

      return `<div data-component="Accordion" data-props='${props}'></div>`;
    } catch (error) {
      console.error('Error parsing accordion JSON:', error);
      return 'error' + match;
    }
  });
};

// Reemplazar los shortcodes por componentes
const replaceShortcodesWithComponents = (html) => {
  html = replaceAboutSection(html);
  html = replaceDepartmentSection(html);
  html = replaceFormStyle1(html);
  html = replaceAccordion(html);
  return html;
};

// Renderizar los componentes en el HTML
const renderComponents = (html) => {
  return parse(html, {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs['data-component']) {
        const Component = {
          DepartmentSectionStyle4,
          FormStyle1,
          AboutSectionStyle2,
          Accordion,
        }[domNode.attribs['data-component']];

        if (Component) {
          let props = {};
          if (domNode.attribs['data-props']) {
            try {
              props = JSON.parse(domNode.attribs['data-props']);
            } catch (error) {
              console.error(
                `Error parsing props for component ${domNode.attribs['data-component']}:`,
                error
              );
            }
          }

          return (
            <Section bottomMd={125} bottomLg={0} bottomXl={85}>
              <Component {...props} />
            </Section>
          );
        }
      }
    },
  });
};

// Componente principal Services
const Services = () => {
  const { slug } = useParams();
  const [page, setPage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const baseUrl = getBaseUrl();
  const location = useLocation();
  const navigate = useNavigate();
  const languageCode = location.pathname.split('/')[1];
  
  // Verificar si el primer segmento de la ruta es un código de idioma válido
  const language = languages[languageCode] ? languageCode : 'es';
  const { setTranslations } = useContext(TranslationContext);

  useEffect(() => {
    // Detecta si la pantalla es móvil
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Define aquí el ancho máximo para móvil (ej. 768px)
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPage = async () => {
      try {
        // Construir el pageSlug correctamente
        const pageSlug = language === 'es' ? slug : `${language}/${slug}`;
        const data = await getPageBySlug(pageSlug);
        const contentWithComponents = replaceShortcodesWithComponents(
          processHtmlContent(data.content, baseUrl)
        );
        setPage({ ...data, content: contentWithComponents });

        // Almacenar las traducciones en el contexto
        const translationsMap = {};

        if (language === 'es') {
          // Estamos en el idioma principal
          translationsMap['es'] = slug;

          if (data.translations && Array.isArray(data.translations)) {
            data.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              translationsMap[t.locale] = slugWithoutLang;
            });
          }
        } else {
          // Estamos en un idioma secundario
          translationsMap[language] = slug;

          if (data.main_page && data.main_page.slug) {
            translationsMap['es'] = data.main_page.slug;
          }

          // Asignar las traducciones de main_page.translations (si existen)
          if (data.main_page && data.main_page.translations && Array.isArray(data.main_page.translations)) {
            data.main_page.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              translationsMap[t.locale] = slugWithoutLang;
            });
          }

          // Manejar traducciones directas si están presentes
          if (data.translations && Array.isArray(data.translations)) {
            data.translations.forEach((t) => {
              const slugWithoutLang = t.slug.startsWith(`${t.locale}/`)
                ? t.slug.slice(t.locale.length + 1)
                : t.slug;
              // Solo agregar si aún no se ha asignado
              if (!translationsMap[t.locale]) {
                translationsMap[t.locale] = slugWithoutLang;
              }
            });
          }
        }

        setTranslations(translationsMap);

        // Verificar el contenido de translationsMap
        console.log('Translations in context:', translationsMap);

      } catch (error) {
        console.error('Error fetching page', error);
        // Si ocurre un error, navegar a la página de inicio
        navigate('/');
      }
    };
    fetchPage();
  }, [slug, baseUrl, language, setTranslations, navigate]);

  if (!page) return null;

  const { title, extras } = page;
  const metaTitle = extras?.meta_title || title;
  const metaDescription = extras?.meta_description || '';
  const bgUrl = extras.bg_url ? `${baseUrl}/${extras.bg_url}` : getFallbackImage('bg');
  const imgUrl = extras.img_url ? `${baseUrl}/${extras.img_url}` : getFallbackImage('img');

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="preconnect" href={baseUrl} crossOrigin="true" />
        <link rel="preload" as="image" href={`${baseUrl}/images/Imagotipo_Blanco.svg`} />
        {extras.img_url && <link rel="preload" as="image" href={imgUrl} />}
      </Helmet>

      <Header logoSrc="/images/Imagotipo_Blanco.svg" />

      {/* Renderiza el banner solo en dispositivos no móviles */}
      {!isMobile && (
        <BannerSectionStyle5
          bgColor="linear-gradient(40deg, #EE6FBB, #7BADD3)"
          imgUrl={imgUrl}
          title={title}
          subTitle={extras.subtitle}
        />
      )}

      {/* Renderiza el banner ligero solo en dispositivos móviles */}
      {isMobile && (
        <div
          style={{
            background: 'linear-gradient(40deg, #EE6FBB, #7BADD3)',
            padding: '100px 0px',
            textAlign: 'center',
            borderBottomLeftRadius: '50px',
            borderBottomRightRadius: '50px',
          }}
        >
          <h1
            style={{
              color: '#ffffff',
              fontSize: '30px',
              marginBottom: '10px',
              marginTop: '30px',
              padding: '0px 20px',
            }}
          >
            {title}
          </h1>
          <p style={{ marginBottom: '10px', padding: '0px 20px' }}>{extras.subtitle}</p>
        </div>
      )}

      <Section className="section-loaded" topMd={60} bottomMd={200}>
        <div className="container container-services">
          <style>{extras.content_css}</style>
          {renderComponents(page.content)}
        </div>
      </Section>
      <Footer />
    </>
  );
};

export default Services;
