// src/App.js

import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import Blog from './components/Pages/Blog';
import BlogDetails from './components/Pages/BlogDetails';
import Services from './components/Pages/Services';
import ErrorPage from './components/Pages/ErrorPage';
import { useTranslation } from 'react-i18next';
import languages from './config/languages';

// Función para cargar scripts de manera dinámica
const loadScript = (src, id, callback) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve();
      return;
    }
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    script.async = true;
    script.onload = () => {
      if (callback) callback();
      resolve();
    };
    script.onerror = () => reject(new Error(`Error al cargar el script: ${src}`));
    document.body.appendChild(script);
  });
};

function App() {
  const { pathname } = useLocation();
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const { i18n } = useTranslation();

  // Idioma principal (predeterminado)
  const defaultLanguage = 'es';

  // Obtener la lista de idiomas soportados desde la configuración
  const supportedLanguages = Object.keys(languages);

  // Scroll al inicio al cambiar de ruta
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Verifica el idioma en la URL y establece 'es' como predeterminado si no hay prefijo
  useEffect(() => {
    const pathSegments = pathname.split('/');
    const pathLang = pathSegments[1];

    if (supportedLanguages.includes(pathLang)) {
      if (i18n.language !== pathLang) {
        i18n.changeLanguage(pathLang);
      }
    } else {
      if (i18n.language !== defaultLanguage) {
        i18n.changeLanguage(defaultLanguage);
      }
    }
  }, [pathname, i18n, supportedLanguages, defaultLanguage]);

  // Cargar scripts después de una interacción del usuario
  useEffect(() => {
    const handleUserInteraction = () => {
      if (!scriptsLoaded) {
        // Configuración de Axeptio
        window.axeptioSettings = {
          clientId: '66865e9cc205eb89f700fcfe',
          cookiesVersion: 'gestlifecookies-es',
          googleConsentMode: {
            default: {
              analytics_storage: 'denied',
              ad_storage: 'denied',
              ad_user_data: 'denied',
              ad_personalization: 'denied',
              wait_for_update: 500,
            },
          },
        };

        // Cargar script de Axeptio
        loadScript('//static.axept.io/sdk.js', 'axeptio-sdk')
          .then(() => {
            console.log('Script de Axeptio cargado correctamente.');
          })
          .catch((error) => {
            console.error(error);
          });

        // Cargar script de Tawk.to
        loadScript('https://embed.tawk.to/5f450aff1e7ade5df443c71e/default', 'tawkto-script', () => {
          var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
          console.log('Tawk.to API inicializada.');
        })
          .then(() => {
            console.log('Script de Tawk.to cargado correctamente.');
          })
          .catch((error) => {
            console.error(error);
          });

        setScriptsLoaded(true);
      }
    };

    const events = ['click', 'scroll', 'keydown', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, handleUserInteraction, { once: true, passive: true });
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleUserInteraction);
      });
    };
  }, [scriptsLoaded]);

  return (
    <Routes>
      {/* Ruta raíz para el idioma predeterminado (español) */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:slug" element={<BlogDetails />} />
        <Route path=":slug" element={<Services />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>

      {/* Generar rutas para los otros idiomas */}
      {supportedLanguages
        .filter((lang) => lang !== defaultLanguage) // Excluir el idioma principal
        .map((lang) => (
          <Route key={lang} path={`/${lang}/*`} element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:slug" element={<BlogDetails />} />
            <Route path=":slug" element={<Services />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        ))}

      {/* Ruta comodín para manejar 404 en caso de rutas no definidas */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
