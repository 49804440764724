// src/components/Pages/BlogDetails.jsx

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Section from '../Section';
import Breadcrumb from '../Breadcrumb';
import { Icon } from '@iconify/react';
import Spacing from '../Spacing';
import Sidebar from '../Sidebar';
import { pageTitle } from '../../helpers/PageTitle';
import { getArticleBySlug } from '../../helpers/GetBlogArticle';
import { Helmet } from 'react-helmet';

export default function BlogDetails() {
  const { slug, lang } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        if (slug) {
          const data = await getArticleBySlug(slug, lang);
          setArticle(data);
        }
      } catch (error) {
        console.error('Error fetching blog article details', error);
      }
    };

    fetchArticle();
  }, [slug, lang]);

  if (!article) {
    return <p>Loading...</p>;
  }

  pageTitle(article.title);

  const bgUrl = "/images/home_1/degradadobckg.webp";

  return (
    <>
      <Helmet>
        <title>{article.meta_title || article.title}</title>
        <meta name="description" content={article.meta_description || ''} />
      </Helmet>

      <Section 
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '50px 0'
        }}
      />
      <Section topMd={170} bottomMd={54} bottomLg={54}>
        <Breadcrumb title={article.title} />
      </Section>
      <div className="container">
        <div className="cs_blog_details_info">
          <div className="cs_blog_details_info_left">
            <div className="cs_blog_details_date">
              {new Date(article.date).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </div>
          </div>
          <div className="cs_social_links_wrap">
            <h2>Compartir:</h2>
            <div className="cs_social_links">
              <Link to="/">
                <Icon icon="fa-brands:facebook-f" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:linkedin-in" />
              </Link>
              <Link to="/">
                <Icon icon="fa-brands:twitter" />
              </Link>
            </div>
          </div>
        </div>
        <Spacing md="55" />
        <img
          src={`https://dashboard.gestlifesurrogacy.com/${article.image}`}
          alt={article.title}
          className="w-100 cs_radius_20"
        />
        <Spacing md="90" lg="50" />
        <div className="row">
          <div className="col-lg-8">
            <div className="cs_blog_details">
              <h2>{article.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </div>
            <Spacing md="85" />
          </div>
          <div className="col-lg-4">
            <Sidebar />
          </div>
        </div>
      </div>
      
    </>
  );
}
